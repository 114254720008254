@import url("https://fonts.googleapis.com/css2?family=Anek+Devanagari:wght@400;500;600;700&display=swap");
.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
}
body {
  font-family: "Anek Devanagari", sans-serif;
  background-color: #000000 !important;
}
.login-page {
  background-color: black;
  height: 100vh;
}
.text-10 {
  font-size: 10px;
}
.text-12 {
  font-size: 12px;
}
.text-14 {
  font-size: 16px;
}
.show-cursor {
  cursor: pointer;
}
.family-card-bg {
  background-color: #3e3e3e;
  font-size: 16px;
}
.voter-info-card,
.voter-list-card {
  padding: 8px 2px;
  background-color: #383838;
  border-radius: 10px !important;
}
.voter-info-card label {
  color: #0010bf;
  font-weight: 600;
  margin-bottom: 5px;
  color: #a9a7a7;
}
.voter-info-card h6 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 21px;
}
.voter-list-label {
  color: #929292 !important;
}
.voter-list-card select {
  font-size: 12px !important;
}
.voter-list-answer {
  color: #ffffff;
}
.voter-list-card {
  font-size: 16px;
  border-radius: 10px !important;
}
/* .voter-list-card label{
    color: #000 !important;
    font-size: 12px !important;
    font-weight: bold !important;
} */
.polling-booth-label {
  color: #929292;
}
.voting-date-time {
  background-color: #383838;
  color: #fff;
  border-top: 1px dashed #fff;
  text-align: center;
  font-size: 12px;
}
.input-box,
.input-box:focus {
  background-color: #383838;
  font-size: 16px;
  font-weight: 400;
  /* border:none; */
  color: #fff !important;
}

.input-box::placeholder,
.scan-card-text {
  font-size: 16px;
  color: #fff !important;
}
.input-box:focus {
  box-shadow: none !important;
  outline: none !important;
}
.sound-btn {
  justify-content: end;
  align-items: center;
  display: flex;
}
.btn-normal {
  border-radius: 20px !important;
  font-size: 16px !important;
  line-height: normal !important;
}
.continue-btn {
  background-color: #14d41c !important;
  color: #fff !important;
}
.show-btn {
  border-radius: 10px !important;
  background-color: #0057ff !important;
  color: #fff !important;
  line-height: normal !important;
}
.cancel-btn {
  background-color: red !important;
  color: #fff !important;
}
.confirm-btnLogin {
  background-color: #0057ff !important;
  border-radius: 50px !important;
  padding: 6px -19px -4px -9px !important  ;
  color: #fff !important;
  font-size: 18px;
  font-weight: 400;
  width: 100%;
  height: 33px;
  max-width: 92px;
  justify-content: center;
  align-items: center;
}
.confirm-btn.success {
  background-color: #14d41c !important;
  border-color: #14d41c;
}
.font-size-rem {
  font-size: 0.9rem;
}
.dashed-border {
  margin-top: 10px;
  border-top: 1px dashed #d9d9d9;
}
.scanner-card {
  background: #0057ff;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  padding: 5px;
}
.camera-icon-size {
  border: 0px solid #000000;
}
.scan-card-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  padding-top: 11px;
  padding-left: 4px;
}
.white-wp-icon {
  margin-right: 6px;
  margin-bottom: 3px;
  height: 16px;
  margin-top: 2px;
}
.audio-img {
  /* filter: invert(1); */
  margin-top: -44px;
  position: absolute;
  margin-right: 0px;
  height: 32px;
  color: #fff !important;
}
.home-img {
  height: 22px;
  cursor: pointer;
  color: #fff !important;
}
.btn-position {
  justify-content: space-around;
  display: flex;
  margin-top: 10px;
  padding: 0px;
}
.oops-msg-text {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
}
.last-card-bottom {
  margin-bottom: 260px;
}
/* input switch css */
.toggle {
  position: relative;
  display: inline-block;
  width: 100px;
  padding: 3px;
  /* height : 25px; */
  border-radius: 30px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  padding-left: 2px;
  background-color: white;
}
.voting.toggle {
  width: 70px;
}
.Polling.toggle {
  width: 48px;
}
.toggle:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 17px;
  border-radius: 50%;
  /* top: 2px; */
  left: 3px;
  transition: all 0.5s;
  /* background-color: black; */
}
.toggle:before {
  content: "";
  margin-left: 6px;
  /* position: absolute; */
  width: 16px;
  height: 17px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: all 0.5s;
  /* background-color: black; */
}
.toggle.checked-toggle-span:after {
  background-color: #0057ff;
  font-size: 10px !important;
}
.toggle.unchecked-toggle-span:after {
  background-color: gray;
  font-size: 10px !important;
}
/* .checkbox:checked + .toggle::after {
  right: 4px;
  left: unset;
} */
.checkbox {
  display: none;
}
/* input switch css end */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
.family-marital-toggle span {
  font: -webkit-control;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
.option-as-placeholder {
  color: #a9a9a9;
}

.scan-btn-position {
  position: absolute;
  width: 100%;
  bottom: 10px;
  left: 3%;
}
.scan-camera-height {
  height: 90% !important;
}
.main-container-login {
  /* background: linear-gradient(348deg, #c7dbb9 0%, #fff 47.29%, #ffd1a6 87.9%); */
  height: 100vh;
}
.main-container-login .card {
  border: none;
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  width: 100%;
}
.app.main-container-login .header {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}
.dashboard-main-page .card {
  background-color: #3e3e3e;
  color: #d9d9d9;
  /* border: 1px solid #929292; */
}
.count-container {
  display: flex;
  align-items: center;
}
.count {
  font-size: 26px;
  /* font-weight: bold; */
  color: #ffffff;
}
.count-text {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}
.card-row {
  margin: 10px 4px;
}
.header-candidate {
  background: #3e3e3e;
  color: #fff;
  padding: 8px 10px;
}
.popup-lang.p-dialog .p-dialog-header {
  display: none;
}
.popup-lang.p-dialog .p-dialog-content {
  background: rgba(0, 0, 0, 0.8);
  display: contents;
  color: #fff;
  text-align: center;
}
.popup-lang.p-dialog {
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.popup-lang.p-dialog .p-dialog-header-icons {
  display: none;
}
.namskar-img {
  width: 60px;
  height: 60px;
  gap: 4px;
}
.header-middle-title {
  color: #fff;
  text-align: center;
  margin-top: 2px;
}

.polling-select,
.polling-select:focus,
.input-color,
.input-color:focus,
.polling-textbox,
.polling-textbox:focus {
  background-color: #3e3e3e;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  border: none;
}
.polling-select.option-as-placeholder,
.input-color.option-as-placeholder,
.polling-textbox::placeholder {
  color: #faf7f7 !important;
}

.header-middle-title {
  color: #fff;
  text-align: center;
  margin-top: 2px;
}
.total-details {
  background-color: #3e3e3e;
  border-color: #929292;
  color: #fff;
  /* font-size: 12px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0;
  border-radius: 4px 4px 0px 0px;
}
.total-details:hover {
  background-color: #0057ff;
  border-color: #0057ff;
}
.votertable {
  background-color: #2c2c2c !important;
  color: #ffffff !important;
  font-size: 12px !important;
  margin-bottom: 20px;
}

.votertable .p-datatable-thead > tr > th {
  background-color: #383838 !important;
  color: #929292 !important;
  padding: 5px !important;
  border: "0px, 0px, 1px, 0px";
  font-size: "16px";
  font-weight: 400;
}
.votertable .p-datatable-tbody > tr > td {
  color: #ffffff !important;
  border-color: #444444 !important;
  font-weight: 400;
  padding: 10px !important;
  /* font-family: "Anek Devanagari"; */
  border: "0px, 0px, 1px, 0px";
}
.votertable .p-datatable-tbody > tr:nth-child(even) > td {
  background-color: #383838 !important;
  /* font-family: "Anek Devanagari"; */
  border: "0px, 0px, 1px, 0px";
}
.votertable .p-datatable-tbody > tr:nth-child(odd) > td {
  background-color: #4b4b4b !important;
  /* font-family: "Anek Devanagari"; */
  border: "0px, 0px, 1px, 0px";
}
.votertable .p-datatable-tbody > tr {
  border-bottom: 1px solid #929292 !important;
  /* font-family: "Anek Devanagari"; */
  border: "0px, 0px, 1px, 0px";
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last,
span.p-paginator-pages button {
  min-width: 2rem !important;
  height: 2rem !important;
}
.confirmed-img {
}
@media (min-width: 768px) {
  .popup-lang {
    overflow: scroll;
  }
  .dialog-img,
  .photowithhand {
    /* height: 300px; */
    /* width: 100%; */
  }
  .card.scanner-card {
    padding: 3px 8px;
  }
  .dashboard-img {
    display: block;
  }
  .mobile-img {
    display: none;
  }
  .newdashboardbanner {
    display: none;
  }
}
@media (max-width: 767px) {
  .scrollable-div {
    height: calc(100vh - 120px);
  }
  .toggle {
    width: 100px;
  }
  .dashboard-img {
    display: none;
  }
  .mobile-img {
    display: block;
    width: -webkit-fill-available;
  }
  .desktop-img {
    display: none;
  }
  .newdashboardbanner {
    width: -webkit-fill-available;
  }
}
@media (min-width: 300px) and (max-width: 375px) {
  .toggle {
    width: 100px;
  }
  .family-marital-toggle.toggle {
    width: 80px;
  }
  .voting.toggle {
    width: 60px;
  }
  .confirm-btn {
    font-size: 16px !important;
  }
  .camera-card {
    min-height: 36px;
  }
  .count-text {
    font-size: 16px;
  }
}
@media (min-width: 300px) and (max-width: 340px) {
  .voting-date-time {
    font-size: 11px;
  }
  .voter-list-card,
  .family-card-bg {
    font-size: 12px;
    border-radius: 0px !important;
  }
}

.digital-lang-text {
  /* font-family: Anek Devanagari; */
  font-size: 22px;
  font-weight: 700;
  /* line-height: 35.61px; */
  text-align: center;
  margin-bottom: 12px;
  margin-top: 10px !important;
}
.userName {
  width: 200px;
  height: 40px;
  top: 432px;
  left: 101px;
  gap: 0px;
  border-radius: 4px 0px 0px 0px;
  opacity: 0px;
}
.loginImage {
  width: 390px;
  height: 200px;
  gap: 0px;
  opacity: 0px;
}
.modal-dark-theme .modal-content {
  background-color: #000;
  color: #fff;
  border: none;
}

.modal-dark-theme .modal-header {
  border-bottom: none;
}

.modal-dark-theme .modal-header .close {
  color: #fff;
}

.modal-dark-theme .modal-body {
  background-color: #000;
}

.modal-dark-theme .form-control {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}

.modal-dark-theme .form-control:focus {
  background-color: #444;
  border-color: #777;
}

.modal-dark-theme .btn.show-btn {
  background-color: #333;
  color: #fff;
  border: 1px solid #777;
}

.modal-dark-theme .btn.show-btn:hover {
  background-color: #555;
}
.ml-right {
  margin-right: 6px;
}
.modal-header .btn-close {
  background-color: white;
}
.address-details {
  background-color: #5f6368;
  border-color: #929292;
  color: #fff;
  font-size: 12px;
  display: flex;
  text-align: center;
  padding: 0;
}

.worker-info-card {
  padding: 8px 2px;
  border-radius: 8px;
  background-color: #3e3e3e;
  border-color: white;
  margin-bottom: 22px;
  width: 339px;
}
/*   
  .worker-info-card .row {
    align-items: center;
  } */

.worker-info-card input[type="checkbox"] {
  margin-right: 10px;
}
.worker-info-card .row {
  padding-bottom: 10px;
}

.worker-info-card span {
  font-size: 16px;
  line-height: 1.5;
}
.redCircle {
  width: 35.37px;
  height: 35.4px;
  top: 10px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}
.selectFieldArea {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}
.handimgcolor {
  color: #14d41c;
  /* font-family: "Anek Devanagari"; */
  font-size: 16px;
  font-weight: 500;
  /* line-height: 22.1px; */
  text-align: center;
}
.textFont {
  /* font-family: "Anek Devanagari"; */
  font-size: 16px;
  font-weight: 500;
  line-height: 22.1px;
  text-align: center;
  /* margin-bottom: 12px;   */
  /* margin-top: 12px; */
}
.toggleON {
  background-color: black;
  color: white;
}
.coverimage {
  height: 134px;
  width: 390px;
}
.form-select:disabled {
  background-color: #3e3e3e;
}
/* General Styles */
.container.black-theme {
  background-color: #121212;
  padding: 20px;
  min-height: 100vh;
}

.card-dark {
  background-color: #1e1e1e;
  border-radius: 8px;
  padding: 20px;
  color: white;
  border: 1px solid #333;
}

.file-upload-dark .p-fileupload-content {
  background-color: #1e1e1e;
  border-color: #333;
}

.file-upload-dark .p-button {
  background-color: #444;
  color: white;
  border: none;
}

.file-upload-dark .p-button:hover {
  background-color: #666;
}

.file-upload-dark .p-fileupload-buttonbar {
  background-color: #2a2a2a;
  border-bottom: 1px solid #444;
}

.text-light {
  color: #f0f0f0;
}

.p-inputtext-dark {
  background-color: #333;
  color: white;
  border: 1px solid #444;
  border-radius: 5px;
}

.p-inputtext-dark::placeholder {
  color: #aaa;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container.black-theme {
    padding: 10px;
  }

  .header .col-9 {
    /* width: 100%; */
    text-align: justify;
  }

  /* .header .col-3 {
    width: 100%;
    text-align: center;
  } */

  .p-field {
    margin-bottom: 10px;
  }

  .p-button {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .text-light {
    font-size: 16px;
  }

  .text-login {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
  .p-inputtext-dark {
    font-size: 16px;
  }

  .p-button {
    font-size: 16px;
    padding: 8px;
  }
}
.ellipse-div {
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
}
.mainbannerdiv {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: auto;
}
@media (max-width: 400px) {
  .p-tabview-nav-link{
    font-size: 14px !important;
  }  
}

@media (max-width: 767px) {
  .mainbannerdiv {
    margin: 0;
  }
  .dialog-img {
    width: 100%;
  }

}
.panja-text {
  color: rgba(20, 212, 28, 1) !important;
}

.capsule-input {
  border-radius: 50px;
  padding: 10px 20px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
}

.capsule-input:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.custom-canvas {
  height: 156px;
  width: 330px;
}
.whatsappbtn {
  background-color: #25d366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.whatsappbtn:hover {
  background-color: #1ebe57; /* Darker shade of WhatsApp green */
}

.whatsappbtn:active {
  background-color: #128c7e; /* Even darker shade when clicked */
}
.text-24 {
  font-size: 24px;
  font-weight: 400;
}
.input-boxLanding,
.input-boxLanding:focus {
  background-color: #383838;
  font-size: 24px;
  font-weight: 400;
  /* border:none; */
  color: #fff !important;
}
.input-boxLanding::placeholder,
.scan-card-text {
  font-size: 16px;
  color: #fff !important;
}
.arrow-img {
  /* filter: invert(1); */
  margin-top: -44px;
  position: absolute;
  margin-right: 0px;
  height: 32px;
  color: #fff !important;
}
.input-boxLandingVoterIDCard,
.input-boxLandingVoterIDCard:focus {
  background-color: #0057ff;
  font-size: 24px;
  font-weight: 400;
  /* border:none; */
  color: #fff !important;
}
.input-boxLandingVoterIDCard::placeholder,
.scan-card-text {
  font-size: 16px;
  color: #fff !important;
}

.slickSide.slick-next {
  right: -9px;
}
.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.total-details-MarriedList {
  background-color: #5f6368;
  border-color: #929292;
  color: #fff;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.main-container-login .cardMarried {
  /* border: 1px solid #929292; */
  padding: 5px;
  border-radius: 0px;
  display: inline-block;
  width: 100%;
}
.dropdown-placeholder .p-dropdown-label {
  background-color: #3e3e3e;
  color: white;
  font-style: italic;
}

.p-dropdown {
  height: 36px;
  line-height: 35px;
}

.p-dropdown-item {
  padding: 5px 10px;
  height: 30px;
  line-height: 20px;
}

.p-dropdown .p-dropdown-label {
  /* font-style: normal; */
  color: white;
  line-height: 15px;
  width: 182px;
  font-size: 16px;
  padding: 5px 7px 0px 0px;
}

.p-dropdown-item {
  background-color: #3e3e3e;
}
.p-dropdown-panel .p-dropdown-items {
  padding: 0px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: white;
  font-size: 16px;
}

.p-dropdown-panel .p-dropdown-header {
  background-color: #3e3e3e;
  padding: 0px;
}
.p-dropdown .p-dropdown-trigger {
  display: none;
}
.total-details-SurnameList {
  /* background-color: #3e3e3e; */
  border-color: #929292;
  color: #fff;
  /* font-size: 12px; */
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  background-color: #3e3e3e;
  color: white;
}

.dropdown-menu {
  z-index: 1000;
}
.chartText {
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 16px;
  font-weight: 400;
}
.slick-prev,
.slick-next {
  z-index: 999 !important;
  top: 50% !important;
  position: absolute !important;
}
.slick-prev {
  left: 15px !important;
}
.slick-next {
  right: 15px !important;
}
.main-container-login .cardSurname {
  /* padding: 5px; */
  border-radius: 0px;
  display: inline-block;
  width: 100%;
  border: none;
}
.slick-slider-polygon .slick-slide {
  padding: 15px; /* Add padding for spacing */
}

.polygon-slide {
  width: 100%;
  height: 300px; /* Adjust height as needed */
  background-color: #4a90e2;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.chartText {
  font-size: 18px;
  font-weight: 400;
}
.custom-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.next-arrow {
  right: 10px; /* Adjust positioning as needed */
  border-width: 15px 20px 15px 0;
  border-color: transparent #333 transparent transparent; /* Adjust colors as needed */
}

.prev-arrow {
  left: 10px; /* Adjust positioning as needed */
  border-width: 15px 0 15px 20px;
  border-color: transparent transparent transparent #333; /* Adjust colors as needed */
}

/* Optional: Adjust slider styles */
.slider-container .slick-slide {
  position: relative;
}
.slick-next:before {
  display: none;
}
.slick-prev:before {
  display: none;
}
.p-dropdown .p-dropdown-clear-icon {
  color: #6b7280;
  right: 1rem;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  background-color: #3e3e3e;
  color: white;
  font-size: 16px;
}
@media (max-width: 767px) {
  /* .col-3 {
    width: auto !important;
  } */
  .pollingrow {
    width: none !important;
    padding-right: calc(var(--bs-gutter-x) * 0.2) !important;
    padding-left: calc(var(--bs-gutter-x) * 0.4) !important;
  }
}

.input-LoginPage,
.input-LoginPage:focus {
  background-color: #383838;
  font-size: 16;
  font-weight: 400;
  color: #fff !important;
}
.input-LoginPage::placeholder,
.scan-card-text {
  font-size: 16px;
  color: #fff !important;
}
.LoginPage .form-control {
  display: flex;
}

.form-check-input:checked {
  background-color: #5f6368;
  border-color: #929292;
}
.text-login {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.confirm-btn {
  background-color: #0057ff !important;
  border-radius: 50px !important;
  padding: 5px 8px !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  max-width: 120px;
  justify-content: center;
  align-items: center;
}
.input-VoterForm,
.input-VoterForm:focus {
  background-color: #383838;
  font-size: 20;
  font-weight: 400;
  color: #fff !important;
}
.input-VoterForm::placeholder,
.scan-card-text {
  font-size: 16px;
  color: #fff !important;
}

.custom-tabview {
  /* background-color: black; */
  color: white;
}

.tabview-description {
  /* margin-bottom: 15px; */
}

.p-tabview-nav-link {
  /* padding: 11px; */
  background-color: black;
  border-radius: 30px;
  color: white;
  text-decoration: none;
  border: none;
  margin-right: 5px;
  height: 29px;
  font-size: 16px;
  font-weight: 300;
}

.input-VoterFormCSSChange,
.input-VoterFormCSSChange:focus {
  background-color: #383838;
  color: white;
  border: none;
}
.p-tabview .p-tabview-nav {
  background-color: #383838;
  /* margin-left: -15px; */
  padding-left: 0px;
  border: 0px;
}

.p-tabview .p-tabview-panels {
  background-color: #383838;
  padding: 0% !important;
  /* margin-top:-37px */
}
.p-component {
  font-family: "Anek Devanagari", sans-serif;
}
.voter-info-cardEndUser,
.voter-list-card {
  padding: 8px 2px;
  /* margin-top: 10px; */
  margin-left: 0px;
  margin-right: -3px;
  background-color: #383838;
  border-radius: 10px !important;
}
.input-VoterFormCSSChange,
.input-VoterFormCSSChange:focus {
  background-color: #4d4c4c;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff !important;
}

.inputBoxCapsule {
  border-radius: 22px;
  /* padding: 3px 0px; */
  margin-top: -42px;
}
.custom-tabview .p-tabview-nav-link {
  color: white;
  background-color: black;
  padding: 19px 4px;
  border-radius: 15px;
}

.p-tabview .p-tabview-nav-link.p-highlight {
  background-color: #383838;
  color: white;
}

.active-tab .p-tabview-nav-link {
  background-color: #0057ff;
  color: white;
  font-weight: 300;
  /* border: 2px solid white;   */
}
.input-VoterFormCSSChange::placeholder {
  color: white;
}
.cast-list {
  color: #ffffff;
  font-size: 18px;
}
/* .header-row {
  background-color: #f2f2f2;
  font-weight: bold;
} */

.header-cell {
  padding: 8px;
}
.displaySearch {
  border: none;
  border-radius: 24px;
  height: 45px;
  background-color: #3e3e3e;
  color: white;
}
.displaySearch::placeholder {
  color: white;
}

.displaySearch:focus {
  background-color: #383838;
  font-size: 16px;
  font-weight: 400;
  /* border:none; */
  color: #fff !important;
}
.show-btndisplayList {
  border-radius: 19px !important;
  background-color: #0057ff !important;
  color: #fff !important;
  line-height: normal !important;
}
.labelDashboard {
  font-size: 18px;
  font-weight: 700;
}

.whiteFont {
  color: #f8f3f3 !important;
}

.padMiddle {
  padding-bottom: 1px;
}
.profile-card {
  display: flex;
  align-items: center;
  gap: 15px;
}

.avatar img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: -46px;
  /* margin-left: -16px; */
  /* /* border: 2px solid #ccc; */
}
.profile-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.profile-info {
  margin-bottom: 10px;
}

.profile-info .name {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.profile-info .title {
  font-size: 18px;
  color: white;
  margin: 5px 0;
}

.profile-info .location {
  font-size: 18px;
  color: white;
  margin: 5px 0;
}

.profile-stats {
  display: flex;
  justify-content: space-between;
}

.voter-detailsFieldWorker {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.voter-detailsFieldWorker .col {
  display: flex;
  flex-direction: column; /* Stack label and value vertically */
  margin-bottom: 10px;
  padding: 1px;
}

.label {
  font-size: 12px;
  color: white;
  margin-bottom: 5px;
}

.value {
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
}
.name {
  font-size: 16px;
  /* font-weight: bold; */
  color: white;
}

@media (min-width: 300px) and (max-width: 375px) {
  .famWidthButton {
    width: 85% !important;
    font-size: 16px;
  }
  .buttonRadius {
    border-radius: 17px !important;
  }
  .boxWidth {
    width: 60%;
  }
  .ageBoothLeft {
    padding-right: 16px;
  }
  .marginTopSurname {
    margin-top: -6px;
  }
  .borderLineSurname {
    border-width: thin !important;
    border-block-start-color: rgb(140, 140, 140) !important;
    border-block-start-style: outset !important;
  }
  .birthdayMarginTop {
    margin-top: 0px;
    border-radius: 0px !important;
  }
  .accordionPosition {
    position: absolute !important;
    /* margin-left: 140px !important; */
  }
  .edit-button {
    height: 28px;
  }
  .marTopFamily {
    margin-top: 10px;
  }
}
.ageBoothLeft {
  padding-right: 20px;
}

.boxColor {
  border-color: grey;
}

.input-color::placeholder {
  color: white;
  opacity: 1;
}

.surnameTextFont {
  color: white;
}
.voter-info-cardEndUser,
.Polling-list-card {
  padding: 8px 2px;
  margin-left: 0px;
  margin-right: -3px;
  background-color: #383838;
  font-size: 16px;
}

.total-details-pollingList {
  background-color: #5f6368;
  border-color: #929292;
  color: #fff;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}
.pollingHeader-details {
  background-color: #3e3e3e;
}
/* Style for active state of the button */
.p-button:active {
  background: #929292; /* Grey background when the button is clicked */
  border: 1px solid #929292; /* Grey border */
}

/* Style for the button when it has an active class */
.p-button.active {
  background: #929292; /* Grey background */
  border: 1px solid #929292; /* Grey border */
}

/* Style for the button during hover and active state */
.p-button:not(:disabled):hover:active {
  background: #929292 !important; /* Grey background when hovering and active */
  color: #ffffff !important; /* White text color */
  border-color: #929292 !important; /* Grey border */
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0px;
}
.birthdayBG-grey {
  background-color: grey;
}

.birthdayBG-grey {
  background-color: grey;
}

.accordionPosition {
  position: absolute !important;
}

.borderFamily {
  /* border-bottom: 1px solid #929292 !important; */
}

@media (min-width: 280px) and (max-width: 480px) {
  .toggleWidth {
    width: 72px !important;
  }
  .searchWidth {
    width: 106% !important;
    margin-left: -10px;
  }

  .editAlign {
    margin-left: 17px;
  }
  .widthCandidate {
    width: 28% !important;
  }
  .modal-header .btn-close {
    background-color: white;
    margin-bottom: 7px;
  }
  .marLeftHeader {
    margin-left: 10px;
  }
  .back-button {
    width: 12% !important;
    height: 28px;
    margin-bottom: 7px;
  }
  .searchNameVoter {
    right: 20px !important;
  }
  .toggleMEET {
    width: 52% !important;
  }
  .textAlign {
    padding-top: 3px !important;
  }
}
.kendraList {
  background-color: #252729;
  border-radius: 10px;
}

.total-details-Surname {
  /* background-color: #3e3e3e; */
  border-color: #929292;
  color: #fff;
  /* font-size: 12px; */
  display: flex;
  flex-direction: column;
  /* text-align: justify; */
  padding: 1;
}
.text-justify {
  text-align: justify;
}

.back-button {
  background: none;
  border: 1px solid grey;
  color: white;
  font: inherit;
  cursor: pointer;
  padding: 0;
  font-size: 20px;
  margin-bottom: 7px;
  border-radius: 8px;
}

.marLeftHeader {
  margin-left: 10px;
}

.padTopInput {
  padding: 8px 0px 0px 0px;
}

.padTopInputSurname {
  padding: 12px 0px 4px 12px;
}

.searchNameVoter {
  position: absolute;
  top: 82px;
  right: 30px;
}
.textColorFamMember {
  color: grey;
}
.formSurname.form-select:disabled {
  background-color: #3e3e3e;
  font-size: 12px;
}
.polling-selectarea.option-as-placeholder,
.input-color.option-as-placeholder,
.polling-textbox::placeholder {
  color: #929292 !important;
}
.containerStyle {
  display: "flex";
  justify-content: center;
}
.option-as-placeholder::placeholder {
  color: white;
}
.imgeArrow {
  margin-top: -44px;
  position: absolute;
}
/* #voterIdInput {
  padding: 16px 0px 6px 0px;
  width: 100%;
}
#nameInput{
  padding: 16px 0px 6px 0px;
  width: 100%;
}
#mobileNoInput{
  padding: 16px 0px 6px 0px;
  width: 100%;
} */
.textSize {
  margin-top: -55px;
}
.FontSize {
  font-size: 16px;
}
.p-inputtext {
  background-color: #383838;
  font-size: 16px;
  font-weight: 400;
  /* border:none; */
  color: #fff !important;
}
.react-datepicker__input-container input::placeholder {
  color: #ffffff; /* Change the color to white */
}

.p-toast-message {
  background-color: #fff !important;  
  opacity: 1 !important;  
}

input.form-control.input-box.input-VoterFormCSSChangetest {
  text-align: center;
  border: none;
}
input.form-control.form-select.mb-1.text-14.polling-select.option-as-placeholder {
  font-size: 12px;
}

.blue-card{
  background-color: #0057ff;
}

.canLoginRemember{
  margin-top: -6px;
}

.candidatePageName{
  background-color: #383838;
  /* border-radius: 10px; */
  margin-left: 274px !important;
  margin-right: 274px !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media (min-width: 280px) and (max-width: 480px) {
  .canLoginRemember{
    margin-left: 54px !important;
  }
  .canLoginButton{
    margin-left: 10px !important;
  }
}

.pollingAgentList {
  background-color: #252729;
}

.fixedPage {
  position: sticky;
  top: 0;
  background-color: black;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.fixedPageNew {
  position: sticky;
  top: 0;
  background-color: #383838;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
}

.addressPadding{
  border-radius: 0px !important;
}

.areaWisePadding{
  border-radius: 0px !important;
  bottom: 5px;
}

@media (min-width: 1224px) and (max-width: 1824px) {
  .candidatePageName{
    margin-left: 179px !important;
    margin-right: 179px !important;
  }
}